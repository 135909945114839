import { toSnakeCase } from '../../utils/format';

export const existsDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue[0] === 'false') {
    return {
      [field]: {
        isNull: true
      }
    };
  } else {
    return {
        [field]: {
          isNull: false
        }
    };
  }
};
export const existsEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  if (filterValue[0] === 'false') {
    return {
      must_not: [
        {
          wildcard: {
            [snkField]: '*@*'
          }
        }
      ]
    };
  } else {
    return {
      must: [
        {
          wildcard: {
            [snkField]: '*@*'
          }
        }
      ]
    };
  }
};
