import { faContactBook } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import Button from '../Button';
import Icon from '../Icon';
import { paths } from '../routes/routes';
import { CONTACTS_ROLE, hasRole } from '../../utils/auth';

const MyContacts: React.FC<ClassNameProps> = styled(({ className }) => {
  const navigate = useNavigate();

  return hasRole(CONTACTS_ROLE) ? (
    <span className={`${className} my-contacts`}>
      <Button
        onClick={() => navigate(paths.myContacts)}
        variant="white"
        title={'Manage and match conctacts to Roseland people records'}
      >
        <Icon size="lg" icon={faContactBook} /> <span className="mobile-hide">My Contacts</span>
      </Button>
    </span>
  ) : (
    ``
  );
})`
  position: relative;
  top: 15px;
  right: 12px;
  display: inline-block;
  .btn.btn-white {
    padding: 0;
    border: 0;
    margin-right: 24px;
    &:hover {
      border: 0;
      background-color: transparent;
    }
  }
`;

export default MyContacts;
