import { isNil, memoize } from 'lodash';
import { DownloadBatchSize } from './components/DownloadCsv';

export const mergeDataSets = (existing: any, incoming: any, { args }) => {
  //if we are querying for bulk download, don't merge here
  if (args?.first === DownloadBatchSize) {
    return incoming;
  }

  if (!existing) {
    existing = {};
  }

  if (!incoming) {
    return existing;
  }

  const hasOffsetArg: boolean = !isNil(args) && !isNil(args.offset);
  const hasCount: boolean = !isNil(incoming.totalCount);
  const hasAggCount: boolean = !isNil(incoming.totalAggCount);

  const merged = { ...existing };

  if (hasOffsetArg) {
    const incomingNodes = incoming.nodes ? incoming.nodes : [];
    const existingNodes = existing?.nodes ? existing?.nodes.slice(0) : [];

    merged.nodes = [...existingNodes, ...incomingNodes];
  }

  if (hasCount) {
    merged.totalCount = incoming.totalCount || 0;
  }
  if (hasAggCount) {
    merged.totalAggCount = incoming.totalAggCount || 0;
  }

  return merged;
};

const concatPagination = memoize((keyArgs: any) => {
  if (keyArgs === void 0) {
    keyArgs = false;
  }
  return {
    keyArgs: keyArgs,
    merge: mergeDataSets
  };
});

const config = {
  typePolicies: {
    Contact:{
      keyFields:['id']
    },
    ContactVisitHistory:{
      keyFields:['id']
    },
    MViewSchool: {
      keyFields: ['type', 'id']
    },
    MViewJudge: {
      keyFields: ['id', 'courtId']
    },
    UsShpPolygon: {
      keyFields: ['osmId']
    },
    MViewLawFirm: {
      keyFields: ['id', 'address']
    },
    MViewSchoolBoardTotal: {
      keyFields: ['schoolBoardDistrictId']
    },
    MViewHigherEdSalariesWithVoterMostRecent: {
      keyFields: ['id']
    },
    ViewLawyersByJudge: {
      keyFields: ['lawyerId']
    },
    ViewDocument: {
      keyFields: ['documentId', 'pageNumber']
    },
    Query: {
      fields: {
        usShpPolygonsConnection: concatPagination(['filter']),
        viewElectionResultsByPrecincts: concatPagination(['elasticSearch', 'orderBy']),
        viewPeopleLists: concatPagination(['elasticSearch', ['query'], 'filter', 'condition', 'first']),
        viewCampaignContributionsConnection: concatPagination(['elasticSearch', 'condition', 'first']),
        mViewCandidateFilingsConnection: concatPagination(['filter', 'condition']),
        viewPppLoans: concatPagination(['elasticSearch', 'condition', 'first']),
        mViewCourtsConnection: concatPagination(['filter']),
        mViewSchoolBoardTotalsConnection: concatPagination(['filter']),
        mViewJudgesConnection: concatPagination(['filter']),
        mViewCasesConnection: concatPagination(['filter']),
        viewCasesConnection: concatPagination(['elasticSearch', ['query'], 'first']),
        mViewLawyersConnection: concatPagination(['filter']),
        mViewLawFirmsConnection: concatPagination(['filter']),
        mViewSchoolsConnection: concatPagination(['filter']),
        mViewHigherEdSalariesWithVoterMostRecentsConnection: concatPagination(['condition', 'filter', 'first']),
        viewCasesByCourtsConnection: concatPagination(['condition', ['courtId']]),
        viewLawyersByCourtsConnection: concatPagination(['condition', ['courtId']]),
        viewJudgesByCourt: concatPagination(['judgeId']),
        viewJudgesByCourtsConnection: concatPagination(['filter', 'condition', ['courtId']]),
        viewCasesByJudgesConnection: concatPagination(['condition']),
        viewOrganizations: concatPagination(['elasticSearch', ['query'], 'first']),
        viewOrganizationEmployments: concatPagination(['filter', 'condition']),
        mViewOrganizationEmployments: concatPagination(['filter', 'condition']),
        viewOrganizationPensions: concatPagination(['filter', 'condition']),
        viewOrganizationUnions: concatPagination(['filter', 'condition']),
        viewPeopleEmployments: concatPagination(['filter', 'condition']),
        viewPeoplePensions: concatPagination(['filter', 'condition']),
        viewPeopleUnions: concatPagination(['filter', 'condition']),
        viewPeopleEducations: concatPagination(['filter', 'condition']),
        viewPeopleProfessionRegulations: concatPagination(['filter', 'condition']),
        viewPeoplePublicOffices: concatPagination(['filter', 'condition']),
        viewPeopleVoterRegistrations: concatPagination(['filter', 'condition']),
        viewPensions: concatPagination(['filter', 'condition']),
        viewUnions: concatPagination(['filter', 'condition']),
        viewNcoaUnregistereds: concatPagination(['filter', 'condition']),
        mViewUnregisteredPeople: concatPagination(['filter', 'condition']),
        mViewVoterFilesConnection: concatPagination(['filter', 'condition']),
        viewLawyersByJudgesConnection: concatPagination(['filter', 'condition']),
        viewCampaignContributions: concatPagination([
          'elasticSearch',
          ['query'],
          'condition',
          ['donorOrganizationId'],
          'first'
        ]),
        mViewNursingHomes: concatPagination(['filter', 'condition']),
        viewPersonAtNursingHomes: concatPagination(['condition', ['nursingHomeId'], 'first']),
        mViewSubsidizedHousings: concatPagination(['filter', 'condition']),
        viewSubsidizedHousings: concatPagination(['filter', 'condition']),
        viewPersonAtSubsidizedHousings: concatPagination(['condition', ['subsidizedHousingId'], 'first']),
        mViewMobileHomes: concatPagination(['filter', 'condition']),
        viewPersonAtMobileHomes: concatPagination(['condition', ['addressId']]),
        viewDormitories: concatPagination(['filter', 'condition']),
        viewPersonAtDormitories: concatPagination(['condition', ['dormitoryId'], 'first']),
        viewProperties: concatPagination(['elasticSearch', ['query'], 'condition', 'first']),
        viewArrests: concatPagination(['filter', 'condition']),
        viewArrestFacets: concatPagination(['filter', 'condition']),
        viewArrestCharges: concatPagination(['condition', ['arrestId'], 'first']),
        viewArrestBailBonds: concatPagination(['condition', ['arrestId'], 'first']),
        viewArrestHoldingFacilities: concatPagination(['condition', ['arrestId'], 'first']),
        viewArrestCourtHearings: concatPagination(['condition', ['arrestId'], 'first']),
        viewVoterWithHistoriesConnection: concatPagination(['condition', ['voterId'], 'first']),
        viewCounties: concatPagination(['filter', 'condition']),
        viewCities: concatPagination(['filter', 'condition']),
        viewEmailOutreaches: concatPagination(['filter', 'condition']),
        viewEmailOutreachDetails: concatPagination(['filter', 'condition']),
        viewStories: concatPagination(['filter', 'condition']),
        contactsConnection: concatPagination(['filter', 'condition']),
        viewVotersAtAddresses: concatPagination(['filter', 'condition']),
        viewSchoolListings: concatPagination(['filter', 'condition']),
        viewStudentListings: concatPagination(['elasticSearch', 'filter', 'condition']),
        viewEmploymentListings: concatPagination(['elasticSearch', 'filter', 'condition']),
        viewDocumentsConnection: concatPagination(['elasticSearch', 'filter', 'condition', 'first']),
        documentCategoriesConnection: concatPagination(['filter', 'condition']),
      }
    }
  }
};

export default config;
