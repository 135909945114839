import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ELECTION_RESULTS_QUERY } from '.';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';
import { sortListBySortParams } from '../../utils/sort';
import { flattenBuckets } from './common';
import { getContestAggregateQuery } from './electionResultsQuery';
import { parties } from './parties';

export const useElectionResultsSummary = (offset: number, _sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sort, setSort] = useState(_sort);

  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['YEAR_DESC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const queryCriteria = {
    filter: { ...getContestAggregateQuery(), ...useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch')?.filter },
    orderBy: sort
  };

  //STATE, CONTEST_CATEGORY, CONTEST_NAME, TYPE, PARTY, YEAR
  const result = useQuery(ELECTION_RESULTS_QUERY, {
    variables: {
      ...queryCriteria
    }, 
    context: { version: '2' } 
  });

  let formatted: any = [];

  const flattened = flattenBuckets(result?.data?.result?.aggBuckets, {});

  flattened.forEach((ga: any) => {
    const existing = formatted.find(
      (f: any) => f.contestName === ga.contestName && f.type === ga.type && f.year === ga.year
    );
    if (existing) {
      existing[parties[ga.party] || 'Other'] = parseInt(ga.votes);
    } else {
      formatted.push({
        contestName: ga.contestName,
        state: ga.state,
        type: ga.type,
        year: ga.year,
        contestCategory: ga.contestCategory,
        [parties[ga.party] || 'Other']: parseInt(ga.votes)
      });
    }
  });

  formatted = sortListBySortParams(formatted, sort);

  return { ...result, ...{ data: { result: formatted } } };
};
