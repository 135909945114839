import { isNestedPath, toSnakeCase } from '../../utils/format';

export const inDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue.length < 1 || filterValue[0] === '') {
    return {};
  }

   if (isNestedPath(field)) {
      const path = field.substring(0, field.indexOf('.'));
      const rest = field.substring(field.indexOf('.') + 1);
  
      return { [path]: inDbFilter(rest, searchParams, filterValue) };
    }

  return {
    [field]: {
      in: filterValue[0]
    }
  };
};

export const inEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);

  if (filterValue.length < 1 || filterValue[0] === '') {
    return { must: [{ match_none: {} }] };
  }
  return {
    must: [
      {
        [snkField]: {
          values: filterValue
        }
      }
    ]
  };
};
