import { isNil } from 'lodash';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';

interface ScrollPaneProps extends ClassNameProps {
  maxHeight?: string;
  height?: string;
  children: React.ReactNode;
  id?: string;
}
const _ScrollPane: React.FC<ScrollPaneProps> = forwardRef<HTMLDivElement, ScrollPaneProps>(
  ({ className, children, maxHeight, height,...rest }, ref) => {
    return (
      <div ref={ref} className={`scroll-pane ${className}`} {...rest}>
        {children}
      </div>
    );
  }
);

const ScrollPane = styled(_ScrollPane)`
  overflow-y: auto;
  ${({ maxHeight }) => (isNil(maxHeight) ? null : 'max-height:' + maxHeight)};
  ${({ height }) => (isNil(height) ? null : 'height:' + height)};
  padding-right: 10px;
  margin-right: -10px;
`;

export default ScrollPane;
