import dayjs from 'dayjs';
import { capitalize, replace } from 'lodash';
import { FILTER_VALUE_SEP } from '../../utils/filter';
import { deleteStartsWith } from '../../utils/search';
import { getStateName } from '../../utils/states';

export const cityNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { city: string }) => capitalize(c.city));
};
export const committeeNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { committeName: string }) => capitalize(c.committeName));
};
export const neighborhoodNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { neighborhood: string }) => capitalize(c.neighborhood));
};
export const subdivisionNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { subdivision: string }) => capitalize(c.subdivision));
};
export const complexNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { complex: string }) => capitalize(c.complex));
};
export const countyNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { county: string }) => c.county || '');
};
export const streetNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { street: string }) => c.street || '');
};
export const universityNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { university: string }) => capitalize(c.university));
};
export const simpleNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { name: any }) => capitalize(c.name));
};
export const factoidTypeMapper = (nodes: any[] = []) => {
  return nodes.map((c) =>
    capitalize(
      c
        .split('.')[0]
        .split(/(?:[-_])/)
        .join(' ')
    )
  );
};

export const simpleMapper = (nodes: any[] = []) => {
  return nodes;
};

export const sexMapper = (nodes: any[] = []) => {
  return nodes.map((n: string) => (n === 'M' ? 'Male' : 'Female'));
};

export const birthDateMapper = (nodes: any[] = []) => {
  return nodes.length > 0 ? [dayjs(nodes[0]).format('MM/DD/YYYY')] : [];
};
export const voterCountMapper = (nodes: any[] = []) => {
  return nodes.map((count: string) => replace(count, FILTER_VALUE_SEP, ' - '));
};

export const stateNameMapper = (nodes: any[]) => {
  return nodes.map((stateAbbr: string) => getStateName(stateAbbr) || '');
};

export const electionTypeNameMapper = (nodes: any[]) => {
  return nodes.map((type: string) => (type === 'GE' ? 'General' : 'Primary' ));
};

export const propertyTypeMapper = (nodes: any[]) => {
  return nodes.map((type: string) => {
    switch (type) {
      case 'L':
        return 'Land';
      case 'C':
        return 'Commercial';
      case 'R':
        return 'Residential';
      default:
        return 'Unknown';
    }
  });
};

export const townshipNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { township: string }) => c.township || '');
};

export const precinctNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { precinct: string }) => c.precinct || '');
};

export const stateHouseNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { stateHouse: string }) => c.stateHouse || '');
};

export const stateSenateNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { stateSenate: string }) => c.stateSenate || '');
};

export const usHouseNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { usHouse: string }) => c.usHouse || '');
};

export const wardNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { ward: string }) => c.ward || '');
};

export const schoolNameMapper = (nodes: any[] = []) => {
  return nodes.map((school) => school || 'Unknown');
};

export const schoolTitleNameMapper = (nodes: any[] = []) => {
  return nodes.map((title) => title || 'Unknown');
};

export const resultIdNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { name: string }) => capitalize(c.name));
};

export const organizationNameMapper = (nodes: any[] = []) => {
  return nodes.map((c: { organization: string }) => c.organization || '');
};

// clear callbacks
export const wardClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('precinctId-eq');
};

export const townshipClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('wardId-eq');
  wardClearCallback(searchParams);
};

export const clearNeighborhoodCallback = (searchParams: URLSearchParams) => {
  // noop
};

export const clearSubdivisionCallback = (searchParams: URLSearchParams) => {
  // noop
};

export const clearComplexCallback = (searchParams: URLSearchParams) => {
  // noop
};

export const emptyClearCallback = (searchParams: URLSearchParams) => {
  // noop
};

export const cityClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('neighborhoodId-eq');
  searchParams.delete('subdivisionId-eq');
  searchParams.delete('complexId-eq');
  searchParams.delete('townshipId-eq');
  searchParams.delete('streetId-eq');
  townshipClearCallback(searchParams);
};

export const countyClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('cityId-eq');
  cityClearCallback(searchParams);
};

export const stateClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('countyId-eq');
  searchParams.delete('stateHouseId-eq');
  searchParams.delete('stateSenateId-eq');
  searchParams.delete('usHouseId-eq');
  countyClearCallback(searchParams);
  deleteStartsWith(searchParams, 'vh');
};

export const categoryClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('subCategory-eq');
  searchParams.delete('documentType-eq');
};

export const subCategoryClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('documentType-eq');
};

export const schoolClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('title-eq');
};

export const addressClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('address-eic');
};
export const propertyClearCallback = (searchParams: URLSearchParams) => {
  searchParams.delete('property');
};
