import { useLazyQuery, useQuery } from '@apollo/client';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ELECTION_RESULTS_QUERY } from '.';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';
import { sortListBySortParams } from '../../utils/sort';
import { flattenBuckets } from './common';
import { getCountyAggregateQuery, getSummaryQuery } from './electionResultsQuery';
import { parties } from './parties';

export const useCountyElectionResultsCountQuery = () => {
  return useLazyQuery(ELECTION_RESULTS_QUERY);
};

export const useCountyElectionResultsListing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { year, type, state, contest } = useParams();

  const [sort, setSort] = useState(searchParams.getAll('sort') || []);
  
  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['COUNTY_NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const query = {
    filter: { ...getCountyAggregateQuery(), ...useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch')?.filter },
    orderBy: sort
  };

  query.filter.query.bool.must = [
    ...(query?.filter?.query?.bool?.must || []),
    { match: { contest_name: { query: contest } } },
    { match: { type: { query: type } } },
    { match: { state: { query: state } } },
    { match: { year: { query: year } } }
  ];

  const result = useQuery(ELECTION_RESULTS_QUERY, {
    variables: {
      ...query
    }, 
    context: { version: '2' }
  });

  let rows: any = flattenBuckets(result?.data?.result?.aggBuckets || { state: { buckets: [] } }, {});

  let formattedResult: any[] = [];
  rows?.forEach((a: any) => {
    let existing = formattedResult.find((r: any) => r.countyId === a.countyId);
    if (isNil(existing)) {
      formattedResult.push({
        countyId: a.countyId,
        countyName: a.countyName,
        [parties[a.party || 'Other']]: parseInt(a.votes)
      });
    } else {
      existing[parties[a.party] || 'Other'] = parseInt(a.votes);
    }
  });

  formattedResult = sortListBySortParams(formattedResult, sort);

  return { ...result, ...{ data: { counties: formattedResult } } };
};

export const useElectionResultsSummaryForCountyList = (contestName: string, type: string) => {
  const countyAggregateSummaryQuery = getSummaryQuery();
  const query: any = {
    ...{
      filter: {
        ...countyAggregateSummaryQuery,
        ...{ query: { bool: { must: [] } }, index: 'electionresults' }
      }
    }
  };

  query.filter.query.bool.must = [
    { match: { contest_name: { query: contestName } } },
    { match: { type: { query: type } } }
  ];

  const result = useQuery(ELECTION_RESULTS_QUERY, { variables: query, context: { version: '2' } });

  let rows: any = flattenBuckets(result?.data?.result?.aggBuckets || { year: { buckets: [] } }, {});

  rows.sort((a: any, b: any) => {
    let result = parseInt(a.year) - parseInt(b.year);
    if (result === 0) {
      result = a.candidateName.localeCompare(b.candidateName);
    }
    return result;
  });

  return { ...result, ...{ data: { result: rows } } };
};
